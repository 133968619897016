import React from 'react'

const ControlIcon = () => {
  return (
    <svg
      width="72"
      height="109"
      viewBox="0 0 72 109"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M43.6291 24.6359C43.6291 30.8252 39.8617 36.4763 34.4797 38.6291V24.0977C34.4797 20.8684 31.7887 18.1774 28.5595 18.1774C24.7921 17.9083 22.1011 20.5993 22.1011 24.0977V38.6291C16.7191 36.2072 12.9517 30.8252 12.9517 24.6359C12.9517 16.2937 19.9483 9.29712 28.2904 9.29712C36.6325 9.29712 43.6291 16.2937 43.6291 24.6359Z"
        stroke="#00ABEC"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path
        d="M50.6254 24.6359C50.6254 32.1707 46.8579 38.8982 41.2068 42.9347C40.9377 42.9347 40.6686 42.9347 40.3995 42.9347C38.2467 42.9347 36.0939 44.2802 35.0175 46.1639C34.7484 46.7021 34.2102 47.7785 34.2102 48.8549V24.0977C34.2102 20.8685 31.5192 18.1775 28.29 18.1775C24.7917 17.9084 22.1007 20.5994 22.1007 24.0977V46.1639C12.6822 43.4729 5.68555 34.8617 5.68555 24.6359C5.68555 12.2573 15.6423 2.30054 28.0209 2.30054C40.6686 2.30054 50.6254 12.2573 50.6254 24.6359Z"
        stroke="#00ABEC"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path
        d="M58.1597 61.2335C58.1597 59.6189 58.967 58.0043 59.7743 56.9279C60.8507 55.8515 62.4653 55.3133 64.0799 55.3133C67.3091 55.3133 70.0001 58.0043 70.0001 61.2335C70.0001 61.2335 70.0001 70.652 70.0001 74.9577C70.0001 87.3363 64.0799 106.981 42.2827 106.981C25.8676 106.981 19.1401 97.8312 15.6418 91.911C12.1435 85.1835 5.68505 68.7683 2.45584 60.1571C1.37944 56.9279 2.72494 53.4296 5.68505 52.0841C6.49235 51.815 7.56875 51.5459 8.37605 51.5459C10.798 51.5459 13.2199 52.8914 14.2963 55.3133L22.3693 74.4195V74.6886"
        stroke="#00ABEC"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M46.3198 54.5059C46.3198 51.2767 49.0108 48.5857 52.24 48.5857C55.4692 48.5857 58.1603 51.2767 58.1603 54.5059V61.2334"
        stroke="#00ABEC"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M58.1599 72.8047V61.2334"
        stroke="#00ABEC"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M46.3195 54.5064V48.5862C46.3195 45.6261 44.1667 42.9351 41.2066 42.666C40.9375 42.666 40.6684 42.666 40.3993 42.666C37.9774 42.666 36.0937 44.0115 35.0173 45.8952C34.4791 46.7025 34.21 47.7789 34.21 48.8553"
        stroke="#00ABEC"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path
        d="M46.3198 67.6918V54.5059"
        stroke="#00ABEC"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M34.2101 48.855V38.6291V24.0977C34.2101 20.8685 31.5191 18.1775 28.2899 18.1775C24.7916 17.9084 22.1006 20.5994 22.1006 24.0977V38.6291V46.164V74.4195V77.9178"
        stroke="#00ABEC"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M34.21 48.855V62.8482"
        stroke="#00ABEC"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  )
}

export default ControlIcon
