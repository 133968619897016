import React from 'react'

const FireAlarmIcon = () => {
  return (
    <svg width="91" height="94" viewBox="0 0 91 94" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M32.9421 38.4493C36.0314 38.4493 38.5357 35.9439 38.5357 32.8534C38.5357 29.7629 36.0314 27.2576 32.9421 27.2576C29.8529 27.2576 27.3485 29.7629 27.3485 32.8534C27.3485 35.9439 29.8529 38.4493 32.9421 38.4493Z"
        stroke="#00ABEC"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path
        d="M83.5183 38.4493C86.6076 38.4493 89.1119 35.9439 89.1119 32.8534C89.1119 29.7629 86.6076 27.2576 83.5183 27.2576C80.429 27.2576 77.9247 29.7629 77.9247 32.8534C77.9247 35.9439 80.429 38.4493 83.5183 38.4493Z"
        stroke="#00ABEC"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path
        d="M32.9422 64.0972C50.1906 64.0972 64.1731 50.109 64.1731 32.8537C64.1731 15.5983 50.1906 1.61011 32.9422 1.61011C15.6938 1.61011 1.71118 15.5983 1.71118 32.8537C1.71118 50.109 15.6938 64.0972 32.9422 64.0972Z"
        stroke="#00ABEC"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path
        d="M48.7909 91.6101H17.0938C16.3946 91.6101 15.9285 91.1438 15.9285 90.4443V76.4546C15.9285 75.7551 16.3946 75.2888 17.0938 75.2888H49.024C49.7232 75.2888 50.1893 75.7551 50.1893 76.4546V90.4443C49.9562 90.9106 49.4901 91.6101 48.7909 91.6101Z"
        stroke="#00ABEC"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path d="M32.942 64.0974V75.056" stroke="#00ABEC" strokeWidth="3" strokeMiterlimit="10" />
      <path
        d="M83.2841 38.4497C82.1188 48.7088 76.2921 70.3927 50.1886 80.6518"
        stroke="#00ABEC"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  )
}

export default FireAlarmIcon
