import React from 'react'
import styled from 'styled-components'

import { Devices } from '@/hooks/useDevice'
import { useViewport } from '@/hooks/useViewport'

import ImageBackground from '@/components/LazyBackgroundImage/ImageBackground'

export const BannerHome = () => {
  const { isMobile, isTabletOnly } = useViewport()

  return (
    <BannerWrapper mobile={isMobile} tablet={isTabletOnly}>
      <img src="/images/logobanner.svg" />
      <ContentWraper>
        <TitleInfo>Welcome to KAT Electronics Corporation</TitleInfo>
        <DescriptionInfo>
          {' '}
          At KAT Electronics, our commitment focuses on tailoring safety solutions meticulously
          crafted to suit the unique needs of every client. Our mission is to enhance both home and
          business security.
        </DescriptionInfo>
        <DescriptionInfo>
          Offering comprehensive solutions in fire and safety systems across Miami-Dade, Broward,
          and Pompano,{!isMobile && <br />} we are your dedicated State Fire Alarm Contractor,
          securing today for a safer tomorrow.
        </DescriptionInfo>
        <DescriptionInfo>
          {' '}
          We guarantee constant protection of your premises by providing 24/7 monitoring of your
          safety.
        </DescriptionInfo>
      </ContentWraper>
    </BannerWrapper>
  )
}
const Welcome = () => {
  const { isMobile, isTabletOnly } = useViewport()

  return (
    <>
      <WelcomeWrapper>
        <ImageBackground name="welcome.jpeg" classes="">
          {!isMobile && !isTabletOnly && <BannerHome />}
        </ImageBackground>
      </WelcomeWrapper>
      {(isMobile || isTabletOnly) && (
        <div style={{ width: '100%' }}>
          <BannerHome />
        </div>
      )}
    </>
  )
}

const WelcomeWrapper = styled.div`
  height: 60vh;
  width: 100vw;
  /* background-image: url(/images/welcome.jpeg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; */
  position: relative;
  margin-bottom: 180px;
  @media ${Devices.mobile.mediaQuery} {
    margin-bottom: 350px;
  }
`
const BannerWrapper = styled.div<{ mobile: boolean; tablet: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 30px;
  gap: 50px;
  background: ${(props) => (props.mobile || props.tablet ? 'none' : '#ffffff')};
  box-shadow: ${(props) =>
    props.mobile || props.tablet ? 'none' : '0px 2px 5px rgba(0, 0, 0, 0.25)'};
  border-radius: ${(props) => (props.mobile || props.tablet ? 'none' : '5px')};
  width: 100%;
  height: auto;
  position: ${(props) => (props.mobile || props.tablet ? 'relative' : 'absolute')};
  top: 109%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media (min-width: 576px) {
    max-width: 540px;
  }
  @media (min-width: 768px) {
    max-width: 720px;
  }
  @media (min-width: 992px) {
    max-width: 930px;
  }
  @media (min-width: 1200px) {
    max-width: 1170px;
  }
  @media ${Devices.mobile.mediaQuery} {
    flex-direction: column;
    width: 100%;
    padding: 0px 24px;
  }
`
const ContentWraper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  width: 100%;
  /* width: calc(100% - 19rem); */
  gap: 5px;
  @media ${Devices.mobile.mediaQuery} {
    width: 100%;
    & > h2 {
      text-align: center;
    }
  }
`

const TitleInfo = styled.h2`
  font-family: 'BankGothic Md BT';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 25px;
  color: #00abec;
  margin-bottom: 8px;
`

const DescriptionInfo = styled.p`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  font-feature-settings: 'liga' off;
  color: #6d6e71;
`

export default Welcome
