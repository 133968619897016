import React from 'react'
// import Slider from './SliderArea'
// import About from './About'
// import CallActionArea from './CallActionArea'
// import FeatureArea from './FeatureArea'
// import SportArea from './SportArea'
// import VideoArea from './VideoArea'
// import Team from './Team/index'
// import Brand from './Brand'
// import Benefit from './Benefit'
// import Testimonial from './Testimonial'
// import Subscription from './Subscribe'
// import Ab from '../About/about'
import Welcome from './Welcome/index';
import ServicesPage from './Services'

const Home = () => {
  return (
    <>
    
    <Welcome/>
      {/* <Slider /> */}
      {/* <CallActionArea /> */}
      {/* <Ab /> */}
      <ServicesPage/>
      {/* <FeatureArea /> */}
      {/* <VideoArea/> */}
      {/* <SportArea /> */}
      {/* <Team />
      <Brand />
      <Benefit />
      <Testimonial />
      <Subscription /> */}
    </>
  )
}

export default Home
