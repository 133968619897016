import React from 'react'

const BDAIcon = () => {
  return (
    <svg width="93" height="88" viewBox="0 0 93 88" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M46.5889 36.5713C52.8633 36.5713 57.9497 31.4849 57.9497 25.2104C57.9497 18.936 52.8633 13.8496 46.5889 13.8496C40.3144 13.8496 35.228 18.936 35.228 25.2104C35.228 31.4849 40.3144 36.5713 46.5889 36.5713Z"
        stroke="#00ABEC"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path
        d="M21.2855 40.1861C18.7035 35.7967 17.1543 30.6327 17.1543 25.2105C17.1543 19.7883 18.7035 14.6243 21.2855 10.2349"
        stroke="#00ABEC"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M71.8931 10.2349C74.4751 14.6243 76.0243 19.7883 76.0243 25.2105C76.0243 30.6327 74.4751 35.7967 71.8931 40.1861"
        stroke="#00ABEC"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M84.8032 2.48877C88.6762 9.20199 91 16.948 91 25.2104C91 33.4729 88.6762 41.2189 84.8032 47.9321"
        stroke="#00ABEC"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M8.11723 47.6738C4.24422 40.9606 1.92041 33.2145 1.92041 24.9521C1.92041 16.6897 4.24422 8.94369 8.11723 2.23047"
        stroke="#00ABEC"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M41.6842 35.28L16.3805 84.3382C15.8641 85.6292 17.1551 86.662 18.1879 85.8874L62.5984 54.6451"
        stroke="#00ABEC"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path
        d="M52.528 35.28L77.8317 84.3382C78.3481 85.6292 77.0571 86.662 76.0243 85.8874L31.6138 54.6451"
        stroke="#00ABEC"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
    </svg>
  )
}

export default BDAIcon
