import React from 'react'

import { graphql, useStaticQuery, Link  } from 'gatsby'
import styled from 'styled-components'
import Item from './Item'
import { Devices } from '@/hooks/useDevice'

export type SingleHomeService = {
  id?: string
  name: string
  imageUrl: string
}

type ServiceHomeProps = {
  node: SingleHomeService
}
const ServicesPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allServicesJson {
        edges {
          node {
            name
            id
            imageUrl
          }
        }
      }
    }
  `)

  return (
    <SerW>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <CTContainer className="text-center mx-auto">
              <TopTitle>Safety Solutions for Electrical, Security and Fire Systems</TopTitle>
            </CTContainer>
          </div>
        </div>
        <ServiceWrapper>
          {data.allServicesJson.edges.map((node: ServiceHomeProps) => {
            return <Link to="/services" activeStyle={{ color: '#00ABEC' }}><Item key={node.node.id} imageUrl={node.node.imageUrl} name={node.node.name} /></Link>
          })}
        </ServiceWrapper>
      </div>
    </SerW>
  )
}

const SerW = styled.div`
  margin-top: 350px;
  margin-bottom: 125px;

  @media (max-width: 575px) {
    padding-left: 9px;
    padding-right: 9px;
  }
  @media ${Devices.tablet.mediaQuery} {
    margin-bottom: 40px;
  }
  @media (min-width: 769px) and (max-width: 991px) {
    margin-top: -100px;
  }
  @media ${Devices.mobile.mediaQuery} {
    margin-bottom: 40px;
    margin-top: -314px;
  }
`

const ServiceWrapper = styled.div`
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  gap: 10px;
  @media ${Devices.mobile.mediaQuery} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`
const TopTitle = styled.h3`
  font-family: 'BankGothic Md BT';
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 42px;
  text-align: center;
  letter-spacing: -0.05em;
  color: #00abec;
  margin-bottom: 30px;
`

const CTContainer = styled.div`
  width: 60%;
  @media ${Devices.tablet.mediaQuery} {
    width: 100%;
  }
  @media ${Devices.mobile.mediaQuery} {
    width: 100%;
    margin-top: 80px;
  }
`
export default ServicesPage
