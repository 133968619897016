import React from 'react'

const AlarmSystem = () => {
  return (
    <svg width="89" height="93" viewBox="0 0 89 93" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M79.7848 91.5H9.61523V84.1271C9.61523 83.3644 10.1237 82.856 10.8864 82.856H78.5136C79.2763 82.856 79.7848 83.3644 79.7848 84.1271V91.5Z"
        stroke="#00ABEC"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path
        d="M76.2254 82.8557H13.4287V75.4829C13.4287 74.7201 13.9372 74.2117 14.6999 74.2117H74.9542C75.7169 74.2117 76.2254 74.7201 76.2254 75.4829V82.8557Z"
        stroke="#00ABEC"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path
        d="M40.8867 56.9239C38.3444 55.6527 36.5647 52.8561 36.5647 49.8053C36.5647 45.229 40.124 41.6697 44.7003 41.6697C49.2766 41.6697 52.8359 45.229 52.8359 49.8053C52.8359 52.8561 51.0562 55.6527 48.5139 56.9239V74.2121H40.8867V56.9239Z"
        stroke="#00ABEC"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path
        d="M44.6995 1.5V10.3983"
        stroke="#00ABEC"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M44.6997 16.2458C30.7166 16.2458 19.5302 27.4323 19.5302 41.4153V74.212H70.1234V41.4153C69.8692 27.4323 58.6827 16.2458 44.6997 16.2458Z"
        stroke="#00ABEC"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path
        d="M87.4121 45.2285H78.5138"
        stroke="#00ABEC"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M10.8864 45.2285H1.98804"
        stroke="#00ABEC"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M81.8184 23.8728L74.1913 28.4491"
        stroke="#00ABEC"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M66.5647 8.36426L61.9884 15.9914"
        stroke="#00ABEC"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M7.58179 23.8728L15.4632 28.4491"
        stroke="#00ABEC"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M23.0894 8.36426L27.4114 15.9914"
        stroke="#00ABEC"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  )
}

export default AlarmSystem
