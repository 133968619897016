import React, { ReactNode } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image'

interface ImageBackgroundProps {
  name: string
  children: ReactNode
  classes: string
}

const ImageBackground = ({ name, children, classes }: ImageBackgroundProps) => {
  const data = useStaticQuery(graphql`
    query Image {
      allFile(filter: { extension: { regex: "/(jpg)|(png)|(jpeg)|(svg)|(webp)/" } }) {
        edges {
          node {
            base
            childImageSharp {
              gatsbyImageData(
                width: 1200
                quality: 100
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
  `)

  const image = data.allFile.edges.find(({ node }: any) => node.base === name)?.node
  const img: IGatsbyImageData | undefined = image ? getImage(image) : undefined

  return (
    <div className={classes}>
      {img && (
        <GatsbyImage
          image={img}
          alt=""
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            width:'100%',
            height:'100%'
          }}
        />
      )}

      {children}
    </div>
  )
}

export default ImageBackground
