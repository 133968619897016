import React from 'react'

const CameraIcon = () => {
  return (
    <svg
      width="110"
      height="77"
      viewBox="0 0 110 77"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.27074 74.8104H3.61197C2.80358 74.8104 2.26465 74.2714 2.26465 73.463V6.90519C2.26465 6.09679 2.80358 5.55786 3.61197 5.55786H9.27074C10.0791 5.55786 10.6181 6.09679 10.6181 6.90519V73.463C10.6181 74.002 10.0791 74.8104 9.27074 74.8104Z"
        stroke="#00ABEC"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path
        d="M16.2772 63.7622H11.9657C11.1573 63.7622 10.6184 63.2233 10.6184 62.4149V51.3669C10.6184 50.5585 11.1573 50.0195 11.9657 50.0195H16.0077C16.8161 50.0195 17.355 50.5585 17.355 51.3669V62.4149C17.6245 63.2233 17.0856 63.7622 16.2772 63.7622Z"
        stroke="#00ABEC"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path
        d="M17.6246 57.0253H48.6131C49.152 57.0253 49.9604 56.7558 50.2299 56.2169L55.3497 46.7856"
        stroke="#00ABEC"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path
        d="M35.6779 2.59369L29.2108 18.2227C28.9413 19.0311 29.2108 19.57 30.0192 19.8394L80.4091 41.3966C80.6786 41.6661 81.2175 41.6661 81.487 41.3966L101.158 30.618C102.236 30.0791 101.966 28.7318 101.158 28.1929L37.5642 1.7853C36.7558 1.51583 36.2169 2.05476 35.6779 2.59369Z"
        stroke="#00ABEC"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path
        d="M30.5588 27.3845L27.8641 32.7738C27.5947 33.5822 27.8641 34.3906 28.4031 34.6601L82.2961 58.373C82.835 58.6424 83.6434 58.373 83.9128 57.8341L90.1105 46.786C90.6495 45.7081 89.5716 44.3608 88.4937 44.8997L81.4877 47.3249C81.2182 47.3249 80.9487 47.3249 80.4098 47.3249L31.9061 27.1151C31.6366 26.5761 30.8282 26.8456 30.5588 27.3845Z"
        stroke="#00ABEC"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path
        d="M99.2714 39.2409L92.8042 53.2531C92.5347 53.792 92.8042 54.6004 93.3431 55.1393L99.8103 58.3729C100.619 58.6424 101.427 58.3729 101.697 57.834L107.894 43.8218C108.164 43.2829 107.894 42.4745 107.355 42.205L101.158 39.2409C100.619 38.4325 99.5408 38.702 99.2714 39.2409Z"
        stroke="#00ABEC"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
    </svg>
  )
}

export default CameraIcon
