import * as React from 'react'
import Layout from '../components/Layout'
import Home from '../components/Home'
import SEO from '@/components/seo'
import { useLocation } from '@reach/router'

const Hom: React.FC<{}> = () => {
  return (
    <Layout>
      <SEO title="Home" canonical={`${useLocation().host}`} />
      <Home />
    </Layout>
  )
}

export default Hom
