import AlarmSystem from '@/components/common/AlarmSystem'
import BDAIcon from '@/components/common/BDAIcon'
import CameraIcon from '@/components/common/CameraIcon'
import ControlIcon from '@/components/common/ControlIcon'
import FireAlarmIcon from '@/components/common/FireAlarmIcon'
import React, { FC } from 'react'
import styled from 'styled-components'
import { SingleHomeService } from '.'
import { Devices } from '@/hooks/useDevice'
import { useViewport } from '@/hooks/useViewport'

const IconImage = (name: string) => {
  switch (name) {
    case 'fireAlarmIcon.svg':
      return (
        <div style={{ transform: 'translateX(10px)' }}>
          <FireAlarmIcon />
        </div>
      )
    case 'Al.svg':
      return <AlarmSystem />
    case 'camera.svg':
      return <CameraIcon />
    case 'bda.svg':
      return <BDAIcon />
    case 'control.svg':
      return <ControlIcon />

    default:
      return <FireAlarmIcon />
  }
}
const Item: FC<SingleHomeService> = ({ imageUrl, name }) => {
  const { isMobile } = useViewport()

  return (
    <div style={isMobile && name === 'Access Control' ? { gridColumn: 'span 2 / span 2' } : {}}>
      <ItemWrapper>
        {IconImage(imageUrl)}
        <TitleContainer>
          <Title>{name}</Title>
        </TitleContainer>
      </ItemWrapper>
    </div>
  )
}

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 20px 31px;
  gap: 20px;
  height: 253px;
  background: #ffffff;
  border: 1px solid #88898b;
  box-shadow: 0px 5.88513px 10.0172px rgba(139, 138, 149, 0.035);
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
  &:hover {
    color: blue;
    background: #00abec;
    border: 1px solid rgba(0, 0, 0, 0.08);
    box-shadow: 0px 5.88513px 10.0172px rgba(139, 138, 149, 0.035);
    border-radius: 10px;
    & > svg path {
      stroke: white !important;
    }
    & > div svg path {
      stroke: white !important;
    }
    & > div h2 {
      color: #ffffff;
    }
  }
  @media ${Devices.tablet.mediaQuery} {
    gap: 0px;
  }
  @media ${Devices.mobile.mediaQuery} {
    gap: 0px;
  }
`

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 8px;

  width: fit-content;
  height: 50px;

  @media (min-width: 1024px) and (max-width: 1199px) {
    height: fit-content;
  }
`
const Title = styled.h2`
  font-family: 'BankGothic Md BT';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 27px;
  text-align: center;
  letter-spacing: -0.05em;
  text-transform: capitalize;
  color: #00abec;
  @media ${Devices.tablet.mediaQuery} {
    line-height: 20px;
  }
  @media ${Devices.mobile.mediaQuery} {
    line-height: 20px;
  }
`
export default Item
